
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const router = useRouter();

    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-login");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-login");

      // Check User is Loggin or not
      if (store.getters.isUserAuthenticated) {
        router.push({ name: "resume" });
      }
    });
  },
});
